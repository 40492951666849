import { useForm } from '@inertiajs/react';
import { ethers } from 'ethers';
import { Link, Code, Button, Input, Checkbox, Spacer, Tab, Tabs, Card, CardBody, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@nextui-org/react';
import { ArrowLeftEndOnRectangleIcon, EnvelopeIcon, UserIcon } from '@heroicons/react/16/solid';
import { UserCircleIcon } from '@heroicons/react/16/solid';
import { LockClosedIcon } from '@heroicons/react/16/solid';
import { WalletIcon } from '@heroicons/react/16/solid';

// Расширение типа для window, чтобы включить свойство ethereum
declare global {
    interface Window {
        ethereum?: any;
    }
}

export default function AuthPage({ status, canResetPassword }: { status?: string, canResetPassword: boolean }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
        wallet_address: '',
        name: '',
        password_confirmation: '',
    });

    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    // Функция для входа через MetaMask
    const checkMetaMask = async () => {
        if (typeof window.ethereum !== 'undefined') {
            try {
                const provider = new ethers.BrowserProvider(window.ethereum);
                const accounts = await provider.send('eth_requestAccounts', []);
                const signer = await provider.getSigner();
                const address = await signer.getAddress();
                setData('wallet_address', address);
                onOpen(); // Открываем модальное окно при успешном подключении MetaMask
            } catch (err) {
                console.error('User denied account access or MetaMask is not installed', err);
            }
        } else {
            console.error('MetaMask is not installed');
        }
    };

    // Обработка формы для входа
    const submitLogin = (e: React.FormEvent) => {
        e.preventDefault();
        post(route('login'), {
            onFinish: () => {
                reset('password');
                window.location.reload(); // Обновляем страницу после успешного входа
            },
        });
    };

    // Обработка формы для регистрации
    const submitRegister = (e: React.FormEvent) => {
        e.preventDefault();
        post(route('register'), {
            onFinish: () => reset('password', 'password_confirmation'),
        });
    };

    // Обработка входа через MetaMask
    const submitMetaMask = () => {
        post(route('wallet-login'));
    };

    return (
        <div className='my-16'>
            <div className='flex justify-center items-center mt-4'>
                <div className='max-w-lg w-full'>
                    <Tabs className='flex justify-center' aria-label="Auth Tabs">
                        <Tab title="Login">
                            <Card className='w-full mx-auto'>
                                <CardBody>
                                    {/* Форма для классического входа */}
                                    <form onSubmit={submitLogin}>
                                        <Input
                                            type="email"
                                            variant='faded'
                                            value={data.email}
                                            placeholder="Enter your email"
                                            startContent={<UserIcon className='size-6' />}
                                            onChange={(e) => setData('email', e.target.value)}
                                            required
                                        />
                                        <Spacer y={1} />
                                        <Input
                                            type="password"
                                            variant='faded'
                                            placeholder="Enter your password"
                                            value={data.password}
                                            startContent={<LockClosedIcon className='size-6' />}
                                            onChange={(e) => setData('password', e.target.value)}
                                            required
                                        />
                                        <Spacer y={1} />

                                        <Spacer y={1} />
                                        <div className="flex items-center justify-between">
                                            <Checkbox
                                                checked={data.remember}
                                                color="success"
                                                onChange={(e) => setData('remember', e.target.checked)}
                                            >
                                                Remember me
                                            </Checkbox>
                                            <Button startContent={<ArrowLeftEndOnRectangleIcon className='size-6' />} variant='faded' className="ms-4" disabled={processing} type="submit">
                                                Log in
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab title="Register">
                            <Card >
                                <CardBody>
                                    {/* Форма для регистрации */}
                                    <form onSubmit={submitRegister}>
                                        <Input
                                            startContent={<UserIcon className='size-6' />}
                                            type="text"
                                            placeholder='Enter your name'
                                            value={data.name}
                                            onChange={(e) => setData('name', e.target.value)}
                                            required
                                        />
                                        <Spacer y={1} />
                                        <Input
                                            startContent={<EnvelopeIcon className='size-6' />}
                                            type="email"
                                            placeholder='Enter your email'
                                            value={data.email}
                                            onChange={(e) => setData('email', e.target.value)}
                                            required
                                        />
                                        <Spacer y={1} />
                                        <Input
                                            startContent={<LockClosedIcon className='size-6' />}
                                            placeholder='Enter your password'
                                            type="password"
                                            value={data.password}
                                            onChange={(e) => setData('password', e.target.value)}
                                            required
                                        />
                                        <Spacer y={1} />
                                        <Input
                                            startContent={<LockClosedIcon className='size-6' />}
                                            placeholder='Confirm your password'
                                            type="password"
                                            value={data.password_confirmation}
                                            onChange={(e) => setData('password_confirmation', e.target.value)}
                                            required
                                        />
                                        <Spacer y={1} />
                                        <Button startContent={<UserCircleIcon className='size-6' />} variant='faded' className="w-full mt-4" disabled={processing} type="submit">
                                            Register
                                        </Button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <div className='flex justify-center items-center'>
                {canResetPassword && (
                    <Link
                        isExternal
                        showAnchorIcon
                        href={route('password.request')}>
                        Forgot your password?
                    </Link>
                )}
            </div>

            {/* Кнопка MetaMask вне вкладок */}
            <Spacer y={2} />

            {/* Модальное окно после успешного подключения MetaMask */}
            <Modal backdrop='blur' isOpen={isOpen} onOpenChange={onOpenChange} placement="center">
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">Wallet Information</ModalHeader>
                    <ModalBody>
                        <Code className='text-center flex items-center justify-center'><WalletIcon className='size-6 mr-2' /> {data.wallet_address}</Code>
                        {errors.wallet_address && <p className="text-red-500">{errors.wallet_address}</p>}
                        <Spacer y={1} />

                    </ModalBody>
                    <ModalFooter>
                        <div className='w-full flex justify-center items-center'>
                            <Button variant='faded' className='w-full' startContent={<img src='/assets/img/auth/meta.png' className='size-6' />} disabled={processing} onPress={submitMetaMask}>
                                Log in with MetaMask
                            </Button>
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <div className='flex justify-center items-center'>
                <Button size='lg' variant='faded' startContent={<img src='/assets/img/auth/meta.png' className='size-6' />} endContent={<img src='/assets/img/trust.png' className='size-6' />} onPress={checkMetaMask}>
                    Connect MetaMask or Trust Wallet
                </Button>
            </div>
        </div>
    );
}
